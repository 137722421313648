import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import PubSub, { publish, subscribe, unsubscribe } from "pubsub-js"
import { TypeWrapper } from "../components/type/TypeContext"
import {
  TypeHeroAnimeVariable,
  TypeHero,
  TypeFamily,
  TypeFamilyVariable,
  AddToCartList,
  AddToCartVariableList,
  TrialGenerator,
  TrialGeneratorNoBackground,
  InUse,
} from "../components/type"
import SanityTexte from "../components/SanityTexte"
import Modal from "../components/ui/Modal"
import { _localizeText } from "../core/utils"
import { VariationsWrapper } from "../components/shop/VariationsContext"

// import useShop from "../components/shop/ShopContext";
// import TrialGeneratorNoBackground from "../components/type/TrialGeneratorNoBackground";
// import TypeFamily from "../components/type/TypeFamily";
// import TypeFamilyVariable from "../components/type/TypeFamilyVariable";
// import AddToCartList from "../components/type/AddToCartList";
// import AddToCartVariableList from "../components/type/AddToCartVariableList";
// import AddToCart from "../components/shop/AddToCart";

export const query = graphql`
  query ProductQ($slug: String!) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      _type
      slug {
        current
      }
      title
      displayTitle
      blurb {
        fr
        en
      }
      body {
        fr: _rawFr(resolveReferences: { maxDepth: 10 })
        en: _rawEn(resolveReferences: { maxDepth: 10 })
      }
      inUse {
        asset: _rawAsset(resolveReferences: { maxDepth: 10 })
      }
      variable
      trial {
        title
        typeface {
          base64
          mimeType
        }
      }
      typefaces {
        title
        typeface {
          mimeType
          base64
          openTypeMetadata
        }
      }
      stylisticSets {
        title
        val
      }
      price
      priceFamily
      priceCustom
      priceComplete
      productVariations: _rawProductVariations(
        resolveReferences: { maxDepth: 15 }
      )
      customCSS
    }
  }
`

const Product = ({ data }) => {
  const product = data.sanityProduct
  const {
    slug,
    title,
    displayTitle,
    body,
    inUse,
    variable,
    trial,
    typefaces,
    stylisticSets,
    customCSS,
  } = product
  console.log("Product", { variable })
  // const [open, setOpen] = useState(false)

  useEffect(() => {
    // if (customCSS) _addCustomCSS()

    const tokenRoute = subscribe("ROUTE_UPDATE", (e, d) => {
      document.body.classList.remove("is-modal-open")
      document.documentElement.classList.remove("no-scroll")
    })

    return () => {
      unsubscribe(tokenRoute)
    }
  }, [])

  const _addCustomCSS = () => {
    const styleSheet = document.createElement("style")
    styleSheet.innerText = customCSS
    styleSheet.id = "product-custom-css"
    document.head.appendChild(styleSheet)
  }

  // useEffect(() => {
  //   console.log({ open })
  //   if (open) {
  //     document.documentElement.classList.add("no-scroll")
  //   } else {
  //     document.documentElement.classList.remove("no-scroll")
  //   }
  // }, [open])

  // console.log(typefaces[0].typeface.base64)
  return (
    <div className="page">
      <SEO
        pageTitle={product.title}
        pageDescription={"'texte.text'"}
        // pageImage={image_featured.url}
        template={`template-product template-product--${slug.current}`}
        page={true}
      />

      {/* <h1>Product</h1> */}
      <div className="product ">
        <div className="header sticky top-md z-50  mb-md px-md">
          <div className="row">
            <div className="col-md-2 col-xs">
              <h1>{product.title}</h1>
            </div>
            {variable && (
              <div className="col-md-2 col-xs hidden-sm">
                <a className="pr-md" href="#section-variable">
                  Variable
                </a>
              </div>
            )}
            <div className="col-md-2 col-xs hidden-sm">
              <a className="pr-md" href="#section-family">
                Fonts
              </a>
            </div>
            {inUse.length > 0 && (
              <div className="col-md-2 col-xs hidden-sm">
                <a className="pr-md" href="#section-in-use">
                  In use
                </a>
              </div>
            )}
            {body && (
              <div className="col-md-2 col-xs hidden-sm">
                <a className="pr-md" href="#section-informations">
                  Informations
                </a>
              </div>
            )}

            {!variable && <div className="col-md-2 col-xs"></div>}

            <div className="col-md-2- col-xs">
              <div className="flex justify-end">
                <TypeWrapper fontName={slug.current} typeface={trial.typeface}>
                  <TrialGenerator input={trial} slug={slug.current} />
                </TypeWrapper>
                <button
                  className="outline ml-sm"
                  onClick={() => publish("MODAL", true)}
                >
                  {_localizeText("buy")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <TypeWrapper fontName={slug.current} typeface={typefaces[0].typeface}>
          {variable && (
            <>
              <section id="section-variable" className="px-md mb-[80px] ">
                <TypeHeroAnimeVariable input={product} />
              </section>
              <hr className="mb-[80px]" />

              <section id="section-family" className="px-md- mb-xxl ">
                {/* ici vient le pb de temps de chargement de la page */}
                <TypeFamilyVariable input={product} />
              </section>
              <hr className="mb-[80px]" />

              {inUse.length > 0 && (
                <section id="section-in-use" className="pl-md- mb-lg">
                  <h2 className="mb-1e pl-md">In use</h2>
                  <InUse input={inUse} />
                </section>
              )}
              {body && (
                <section
                  id="section-informations"
                  className="px-md mb-md text-xl"
                >
                  <h2 className="mb-1e">Informations</h2>
                  <SanityTexte input={body} />
                </section>
              )}

              <Modal>
                <VariationsWrapper>
                  <AddToCartVariableList input={product} />
                </VariationsWrapper>
              </Modal>
            </>
          )}

          {!variable && (
            <>
              <section id="section-hero" className="px-md mb-[80px] ">
                <TypeHero
                  fontName={slug.current}
                  title={title}
                  displayTitle={displayTitle}
                />
              </section>
              <section id="section-family" className="px-md mb-xxl ">
                <TypeFamily input={product} />
              </section>
              <hr className="mb-[80px]" />

              {inUse.length > 0 && (
                <section id="section-in-use" className="pl-md- mb-lg">
                  <h2 className="mb-1e pl-md">In use</h2>
                  <InUse input={inUse} />
                </section>
              )}
              {body && (
                <section
                  id="section-informations"
                  className="px-md mb-md text-xl"
                >
                  <h2 className="mb-1e">Informations</h2>
                  <SanityTexte input={body} />
                </section>
              )}

              <Modal>
                <VariationsWrapper>
                  <AddToCartList input={product} />
                </VariationsWrapper>
              </Modal>
            </>
          )}
        </TypeWrapper>
      </div>
    </div>
  )
}

export default Product
