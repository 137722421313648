import { publish, subscribe, unsubscribe } from "pubsub-js"
import React from "react"
import { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"
import styled from "styled-components"
import { _localizeText } from "../../core/utils"

const Container = styled.div`
  background: var(--bg);
  transition: transform 0.4s ease;
  transform: ${props => (props.open ? "translateY(0)" : "translateY(100%)")};
  pointer-events: ${props => (props.open ? "all" : "none")};
  padding-top: var(--header-height);

  @media all and (max-width: 768px) {
    height: var(--app-height) !important;
  }
  /* .modal-inner {
    border: 1px solid orange;
  } */
  .header {
    /* position: absolute; */
    top: 0 !important;
  }
`

const Modal = ({ children }) => {
  const [open, setOpen] = useState(false)
  // console.log({ open })
  // const ref = useRef()
  // console.log("rendr modal")
  useEffect(() => {
    const tokenModal = subscribe("MODAL", (e, d) => {
      // console.log(e, d)
      setOpen(d)
    })

    return () => unsubscribe(tokenModal)
  }, [])

  useEffect(() => {
    if (open) {
      // ref.current.style.transform = "translateY(0)"
      // ref.current.style.pointerEvents = "all"
      document.body.classList.add("is-modal-open")
      document.documentElement.classList.add("no-scroll")
    } else {
      // ref.current.style.transform = "translateY(50%)"
      // ref.current.style.pointerEvents = "none"
      document.body.classList.remove("is-modal-open")
      document.documentElement.classList.remove("no-scroll")
    }
  }, [open])

  return (
    <Container
      className="modal fixed bottom-0 w-full h-screen overflow-y-auto z-50 px-md"
      open={open}
      // ref={ref}
    >
      <div className="modal-inner">
        <div className="header flex justify-end sticky z-10 absolute- right-0 top-0  ">
          <button className="outline" onClick={() => publish("MODAL", false)}>
            ×
          </button>
        </div>
        <div className="body pb-lg z-0">{children}</div>
      </div>
    </Container>
  )
}

export default Modal
